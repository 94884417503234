body {
  margin: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  background-image: url(./images/lgi01a201411091900.jpg);
	width : 100%;
  height : 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@font-face {
  font-family: 'Fafers';
  src: local('Fafers'), url(./fonts/FAFERS.TTF) format('truetype');
}

@font-face {
  font-family: 'Lato-Regular';
  src: local('Lato-Regular'), url(./fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Bold';
  src: local('Lato-Bold'), url(./fonts/Lato-Bold.ttf) format('truetype');
}